import React from 'react'

// Components
import Posts from 'components/flex/Posts/Overview/Posts'
import Projects from 'components/flex/Posts/Overview/Projects'
import Vacancies from 'components/flex/Posts/Overview/Vacancies'

export interface PostsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  post?: any
  location?: any
}

interface PostProps {
  [key: string]: any
}

const Shell: React.FC<PostsProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    blog: Posts,
    projects: Projects,
    vacancies: Vacancies,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default Shell
