import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Components
import GridPrimaryAlternate from 'components/flex/Posts/Layout/GridPrimaryAlternate'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Posts: React.FC<PostsProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.postsQueryQuery>(graphql`
    query postsQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section className="my-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="posts"
        limit={Number(fields.limit)}
      >
        <div className="container">
          <GridPrimaryAlternate fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Posts
