import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Layout
import GridPrimary from 'components/flex/Posts/Layout/GridPrimary'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const FilterWrapper = styled.div`
  background-color: #f8f8f8;

  & h2 {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.primary};
  }
`

const Projects: React.FC<PostsProps> = ({ fields }) => {
  const {
    allWpProject,
    allWpProjectcategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectsQueryQuery>(graphql`
    query projectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
      allWpProjectcategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
            icon {
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpProjectcategory.edges}
        categoryName="projectcategories"
        id="projects"
        limit={Number(fields.limit)}
      >
        {/* <FilterWrapper className="pt-4 pb-5">
          <h2 className="text-center mb-4">Projecten</h2>
          <BlogFilters />
        </FilterWrapper> */}

        <div className="container mt-5">
          <GridPrimary fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

// TEMP DISABLE
// const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
//   font-weight: ${({ theme }) => theme.font.weight.bold};
//   background-color: ${({ theme }) => theme.color.light};
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
//   line-height: 14px;
//   color: #5e5e5e;
//   transition: all 0.2s ease-in-out;
//   border: 2px solid ${({ theme }) => theme.color.light};

//   ${(props) =>
//     props.selected &&
//     css`
//       box-shadow: unset;
//       border: 2px solid #5e5e5e;
//     `}

//   &:hover {
//     box-shadow: unset;
//   }
// `

// const Icon = styled(Plaatjie)`
//   width: 31px;
//   height: 31px;
// `

// interface BlogFiltersProps {}

// interface BlogFilterProps {
//   node: {
//     id: string
//     name: string
//     icon: any
//   }
// }

// TEMP DISABLE
// const BlogFilters: React.FC<BlogFiltersProps> = () => {
//   const blogBink = useBlogBink()

//   return (
//     <div className="container">
//       <div className="row justify-content-center">
//         {blogBink.categories.map((category: BlogFilterProps) => (
//           <div className="col">
//             <StyledBlogFilter
//               key={category.node.id}
//               id={category.node.id}
//               selected={blogBink.isSelected(category.node.id)}
//               className="d-flex align-items-center p-3 mb-4 w-100"
//             >
//               <Icon image={category.node.icon.icon} alt="" />
//               <span className="ms-4 text-start">{category.node.name}</span>
//             </StyledBlogFilter>
//           </div>
//         ))}
//       </div>
//     </div>
//   )
// }

export default Projects
