/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'

// Layout
import PostSecondary from 'components/flex/Posts/Layout/PostSecondary'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'
import VacancyFilters from '../Filters/VacancyFilters'

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.dark};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
    }
  }
`

const GridSecondary: React.FC<PostsProps> = ({ fields }) => {
  const blogBink = useBlogBink()
  const [filters, setFilters] = useState<string[]>([])
  const [posts, setPosts] = useState(blogBink.posts)

  useEffect(() => {
    if (filters.length > 0) {
      setPosts(
        blogBink.posts.filter((post) => {
          const hasLocation = filters.includes(post.node.recap.location)
          const hasFunction = filters.includes(post.node.title)

          if (filters.length === 1) {
            if (hasLocation || hasFunction) {
              return true
            }
          }

          if (hasLocation && hasFunction) {
            return true
          }

          return false
        })
      )
    } else {
      setPosts(blogBink.posts)
    }
  }, [filters])

  return (
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <Content content={fields.description} className="mb-3 mb-lg-5" />
        <VacancyFilters
          filters={filters}
          setFilters={setFilters}
          usedPosts={posts}
          posts={blogBink.posts}
        />
        <div className="row">
          {posts.map((post, index) => (
            <React.Fragment key={index}>
              {/* @ts-ignore */}
              <PostSecondary post={post.node} fields={fields} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GridSecondary
