import React, { useEffect } from 'react'

// Interface
import { BlogBinkPosts } from '@ubo/blog-bink'
import styled from 'styled-components'

type VacancyFiltersProps = {
  posts: BlogBinkPosts
  usedPosts: BlogBinkPosts
  filters: string[]
  setFilters: React.Dispatch<React.SetStateAction<string[]>>
}

const VacancyFilterContainer = styled.div`
  margin-bottom: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #5e5e5e;
  font-family: ${({ theme }) => theme.font.family.primary};
`

const Select = styled.select`
  width: 155px;
  height: 31px;
  font-weight: 200;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 2px 12px;

  &::-ms-expand {
    display: none;
  }
`

const CustomChevron = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin-top: auto;
  margin-bottom: auto;
`

const DeleteFilters = styled.div`
  color: #5e5e5e;
  font-weight: 200;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.color.secondary};
  }
`

const VacancyFilters: React.FC<VacancyFiltersProps> = ({
  posts,
  usedPosts,
  filters,
  setFilters,
}) => {
  const [cities, setCities] = React.useState<string[]>([])
  const [functions, setFunctions] = React.useState<string[]>([])

  useEffect(() => {
    const c: string[] = []
    const f: string[] = []

    const tobeused = filters.length > 0 ? usedPosts : posts

    tobeused.forEach((post) => {
      if (post.node.recap.location) {
        if (!c.includes(post.node.recap.location)) {
          c.push(post.node.recap.location)
        }
      }
      if (post.node.title) {
        if (!f.includes(post.node.title)) {
          f.push(post.node.title)
        }
      }
    })

    setCities(c)
    setFunctions(f)
  }, [usedPosts])

  return (
    <VacancyFilterContainer>
      <Title>Filters</Title>
      <div className="position-relative">
        <Select
          id="cityfilter"
          onChange={(e) => {
            setFilters([
              ...filters.filter((f) => !cities.includes(f)),
              e.target.value,
            ])
          }}
          value={filters.filter((f) => cities.includes(f))[0]}
          defaultValue=""
        >
          <option disabled value="">
            Plaats
          </option>
          {cities.map((city) => (
            <option value={city}>{city}</option>
          ))}
        </Select>
        <CustomChevron
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99985 7.50003C6.73724 7.50049 6.47713 7.44904 6.23446 7.34865C5.9918 7.24825 5.77137 7.10089 5.58585 6.91503L0.292847 1.62103L1.70685 0.207031L6.99985 5.50003L12.2928 0.207031L13.7068 1.62103L8.41385 6.91403C8.2284 7.10007 8.00801 7.24762 7.76535 7.34819C7.52268 7.44875 7.26253 7.50036 6.99985 7.50003Z"
            fill="#EE7202"
          />
        </CustomChevron>
      </div>
      <div className="position-relative">
        <Select
          id="functionfilter"
          onChange={(e) => {
            setFilters([
              ...filters.filter((f) => !functions.includes(f)),
              e.target.value,
            ])
          }}
          defaultValue=""
        >
          <option disabled value="">
            Functie
          </option>
          {functions.map((func) => (
            <option value={func}>{func}</option>
          ))}
        </Select>
        <CustomChevron
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99985 7.50003C6.73724 7.50049 6.47713 7.44904 6.23446 7.34865C5.9918 7.24825 5.77137 7.10089 5.58585 6.91503L0.292847 1.62103L1.70685 0.207031L6.99985 5.50003L12.2928 0.207031L13.7068 1.62103L8.41385 6.91403C8.2284 7.10007 8.00801 7.24762 7.76535 7.34819C7.52268 7.44875 7.26253 7.50036 6.99985 7.50003Z"
            fill="#EE7202"
          />
        </CustomChevron>
      </div>
      <DeleteFilters
        onClick={() => {
          setFilters([])
          const cityFilter = document.querySelector('#cityfilter')
          const functionFilter = document.querySelector('#functionfilter')
          if (cityFilter) {
            // @ts-ignore
            cityFilter.value = ''
          }
          if (functionFilter) {
            // @ts-ignore
            functionFilter.value = ''
          }
        }}
      >
        Verwijder filters
      </DeleteFilters>
    </VacancyFilterContainer>
  )
}

export default VacancyFilters
